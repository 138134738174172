'use client';

import { JobData, getJobDataListFromFilter } from "app/browser/[id]/ServerActions";
import { JobListFilter } from "app/components/FilterableListbox-utils";
import JobCard from "app/components/JobCard";
import StyledSpinner from "app/components/StyledSpinner";
import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { log } from '@logtail/next';

export default function FeaturedAnimationsGrid() {
    const [animationsToDisplay, setAnimationsToDisplay] = useState<JobData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const filter : JobListFilter = {
            featuredOnly: true,
            publicOnly: true,
            take: 24,
        }
        getJobDataListFromFilter(filter).then((result) => {
            setAnimationsToDisplay(result.jobData);
        }).catch((error:unknown) => {
            log.error("Browser error: failed to get list of animations to display", {error, filter});
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return loading ? <StyledSpinner /> : (
        <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
            <Masonry>
                {animationsToDisplay.map((animation, idx) => (
                    animation.displayData && <JobCard useAspectRatio={true} key={`job-${idx}`} displayData={animation.displayData}  showStatus={false} showArtist={true} showTitle={false} showCreateDate={false} />
                ))}
            </Masonry>
        </ResponsiveMasonry>
    )
}

