'use client'

import { Alert, Button, ButtonProps } from "flowbite-react";
import StyledSpinner from "app/components/StyledSpinner";
import { PropsWithChildren, startTransition, useState } from "react";
import { startCheckoutSession } from "../settings/ServerActions";
import { Message } from "app/types";
import { Product } from "lib/payment/Products";
import { log }  from '@logtail/next';

type CheckoutButtonProps = {
    product : Product
} & ButtonProps

export default function SettingsCheckoutButton({ product, children, ...buttonProps }: PropsWithChildren<CheckoutButtonProps>) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Message>();

    const handleClick = () => {
        setLoading(true);
        try {
            startTransition(() => {
                // TODO: the call from the component back into settings ServerActions is a ugly.
                void startCheckoutSession(product);
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error : any) {
            log.error(`Failed to start checkout session: ${error}`);
            setError({
                type: 'failure',
                message: `Failed to start checkout session: ${error}`
            })
        } finally {
            setLoading(false);
        }
    }

    return <>
        <Button
            onClick={() => handleClick()}
            {...buttonProps} >{children} {loading && <StyledSpinner />}</Button>
        {error && <Alert color={error.type}>{error.message}</Alert>}
    </>

}