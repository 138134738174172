'use client';

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import SettingsCheckoutButton from "./SettingsCheckoutButton";
import { CREDITS_PER_TOPUP, Product } from "lib/payment/Products";
import { classNames } from 'lib/generic-utils';
import { DEFAULT_MAX_ACTIVE_JOBS_PER_USER } from 'lib/config';

type PricingCardProps = {
    className?: string,
    title: string,
    product: Product,
    showCheckoutButton: boolean
}

  const PricingCard = ({ className, title, product, showCheckoutButton }: PricingCardProps) => {
    const credits = product.creditDelta || CREDITS_PER_TOPUP;

    return (
        <div className={classNames(className || '', "bg-white p-6 shadow-md border dark:border-neutral-600 dark:bg-neutral-900")}>
            <h5 className="text-gray-900 text-2xl leading-tight mb-2 text-center mx-auto dark:text-white">{title}</h5>
            <h5 className="text-gray-900 text-xl leading-tight font-extrabold mb-2 text-center mx-auto dark:text-white">{credits} credits{product.isSubscription ? "/month" : ""}</h5>
            <p className="text-gray-900 text-sm leading-tight mb-6 text-center mx-auto dark:text-white">{credits}×15s animations{product.isSubscription ? "/month" : ""}</p>

            <div className="flex justify-center items-center relative">
                <div className="text-gray-700 text-4xl font-bold dark:text-white">
                    ${product.priceUSD}
                </div>
                <span className="text-gray-700 text-xs ml-2 dark:text-white">
                    USD
                    {product.isSubscription ? <><br />per month</> : <><br />one-time</>}
                </span>
            </div>

            {showCheckoutButton && <SettingsCheckoutButton
                color="dark"
                product={product}
                className="w-3/4 mx-auto mt-6 bg-black text-white border-2 border-white rounded-none">Subscribe
            </SettingsCheckoutButton>
            }

            <ul className="text-gray-600 text-sm mt-8 dark:text-white">
                <li className="flex space-x-2 mb-2">
                    <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                    <span><strong>{credits}</strong> credits {product.isSubscription ? "every month" : " one-off purchase"}</span>
                </li>
                {product.isSubscription &&
                    <li className="flex space-x-2 mb-2">
                        <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                        <span><strong>${product.topupProduct?.priceUSD}</strong> USD to top up by 20 credits at any time <small>(standard price: $8)</small></span>
                    </li>
                }
                {!product.isSubscription &&
                    <li className="flex space-x-2 mb-2">
                        <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                        <span>Top up again any time</span>
                    </li>
                }
                <li className="flex space-x-2 mb-2">
                    <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                    <span className="cursor-pointer text-blue-300 dark:text-blue-200 hover:underline hover:decoration-2" onClick={() => window.open('https://deforum.github.io/posts/deforum-studio-subscription-FAQ/#general-commercial-terms', '_blank')}>
                        General commercial terms
                    </span>
                </li>
                {product.isSubscription && (
                    <li className="flex space-x-2 mb-2">
                        <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                        <span>Cancel any time</span>
                    </li>
                )}
                <li className="flex space-x-2 mb-2">
                    <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                    <span>Up to {product.maxConcurrent ?? DEFAULT_MAX_ACTIVE_JOBS_PER_USER} concurrent generations</span>
                </li>
                {product.allowPrivateGens &&
                    <li className="flex space-x-2 mb-2">
                        <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                        <span><strong>Private generations</strong>: hide your animations from the public feed and Discord channels.</span>
                    </li>
                }
                {product.isSubscription && product.priceUSD === 30 && (
                    <li className="flex space-x-2 mb-2">
                        <IoCheckmarkCircleOutline className="inline flex-shrink-0 mt-1" color="green" size={15} />
                        <span>Use Discord bot via Direct Message</span>
                    </li>
                )}

            </ul>

            <p className="text-xs text-gray-600 dark:text-gray-400 mt-2 text-center w-full">
                Any sales tax specific to your location will be added at checkout time.
            </p>
        </div>
    );
};

export default PricingCard;
