
export const CREDITS_PER_TOPUP = 20;

export type Product = {
    priceUSD: number,
    name: PRODUCT_NAME,
    id: PRODUCT_ID,
    tier?: number,
    maxConcurrent?: number,
    creditDelta?: number,
    allowPrivateGens?: boolean,
    topupProduct?: Product,
    isSubscription: boolean,
}

export type PRODUCT_NAME = "20 Credits"
    | "20 Credits (Exporer Discount)" 
    | "20 Credits (Pro Discount)" 
    | "Explorer Subscription"
    | "Pro Subscription";

// never change these
export type PRODUCT_ID = "TOPUP_20" 
    | "TOPUP_20_TIER1" 
    | "TOPUP_20_TIER2"  
    | "SUB_TIER1" 
    | "SUB_TIER2" 

export const PRODUCT_TOPUP_20 : Product = {
    id: "TOPUP_20",
    isSubscription: false,
    priceUSD: 8,
    name: "20 Credits",
}

export const PRODUCT_TOPUP_20_TIER1_DISCOUNT : Product = {
    id: "TOPUP_20_TIER1",
    tier: 1,
    isSubscription: false,
    priceUSD: 6.75,
    name: "20 Credits (Exporer Discount)",
}

export const PRODUCT_TOPUP_20_TIER2_DISCOUNT : Product = {
    id: "TOPUP_20_TIER2",
    tier: 2,
    isSubscription: false,
    priceUSD: 5,
    name: "20 Credits (Pro Discount)",
}

export const SUBSCRIPTION_TIER_1 : Product = {
    id: "SUB_TIER1",
    isSubscription: true,
    priceUSD: 15,
    tier: 1,
    maxConcurrent: 3,
    name: "Explorer Subscription",
    creditDelta: 50,
    allowPrivateGens: false,
    topupProduct: PRODUCT_TOPUP_20_TIER1_DISCOUNT,
}

export const SUBSCRIPTION_TIER_2 : Product = {
    id: "SUB_TIER2",
    isSubscription: true,
    priceUSD: 30,    
    tier: 2,
    maxConcurrent: 5,
    name: "Pro Subscription",
    creditDelta: 120,
    allowPrivateGens: true,
    topupProduct: PRODUCT_TOPUP_20_TIER2_DISCOUNT,
}

export const ALL_PRODUCTS = [PRODUCT_TOPUP_20, PRODUCT_TOPUP_20_TIER1_DISCOUNT, PRODUCT_TOPUP_20_TIER2_DISCOUNT, SUBSCRIPTION_TIER_1, SUBSCRIPTION_TIER_2]
export const ALL_SUBSCRIPTIONS = ALL_PRODUCTS.filter(p => p.isSubscription);
